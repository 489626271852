.dark{
    --info-font-color: #f6f6f6;
    --nav-bg-color: #1d1d1d;
    --shadow-color: transparent;
    --background-color: #1D1F21;
    --panel-bg-color: #333333;
    --global-font-color: #CCCCCC;
    --link-blue: #259dd4;
    --btn-bg: #292929;
    --btn-mask-color: #fffaf0;
    --audio-panel: rgba(59,59,59,.7);
    --audio-font-color: #cccccc;
    --audio-hover-color: #1D1F21;
    --i18n-bg: #1D1F21;
    --sidebar-before: #fff;
    --float-nav-toTopbtn-bg: #259dd4;
    --float-nav-side-btn-bg: #da622b;
    --inform-font-color: #fff;

    --xiaoxi-color: #d96c7f;
    --xiaotao-color: #f2cefc;
    --xiaorou-color: #51ceca;
    --reine-color: #a272f4;
    --ailurus-color: #fdc888;
    --yangbao-color: #696969;
}
