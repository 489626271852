.light{
    --info-font-color: #1c264a;
    --nav-bg-color: #ffffff;
    --shadow-color: #c0c0c0;
    --background-color: #f6f6f6;
    --panel-bg-color: #ffffff;
    --global-font-color: #999999;
    --link-blue: #259dd4;
    --btn-bg: white;
    --btn-mask-color: #ff753a;
    --audio-panel: rgba(255,255,255,.7);
    --audio-font-color: black;
    --audio-hover-color: #999999;
    --i18n-bg: #FFFAFA;
    --sidebar-before: #000;
    --float-nav-toTopbtn-bg: #0fa5ea;
    --float-nav-side-btn-bg: #f05a15;
    --inform-font-color: #fff;

    --xiaoxi-color: #CD3951;
    --xiaotao-color: #f2cefc;
    --xiaorou-color: #89dedb;
    --reine-color: #a272f4;
    --ailurus-color: #f4bb84;
    --yangbao-color: #696969;
}

// @media (prefers-color-scheme: light) {
//     :root{
        
//     }
// }
