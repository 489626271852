.btn-wrapper{
    height: var(--btn-height);
    margin: .5rem 1rem;
    padding: 0 1rem;
    border-radius: var(--btn-radius);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 1px 1px 1px var(--shadow-color);

    &:hover{
        transition: box-shadow .13s linear;
        box-shadow: 0px 2px 2px 1px var(--shadow-color);
    }

    .btn-name{
        position: relative;
        font-size: var(--btn-font-size);
        word-wrap: break-word;
        font-weight: 600;
        letter-spacing: .5px;
        color: var(--btn-bg);
    }

    div.mask{
        position: absolute;
        border-color: transparent;
        border-style: solid;
    }

    .mask-gray{
        width: calc(100% - 5px);
        height: calc(100% - 5px);
        border-radius: calc(var(--btn-radius));
        // background-color: var(--btn-bg);
    }

    .left-mask{
        left: 0;
        height: var(--btn-height);
        width: var(--btn-radius);

        div{
            width: 100%;
            height: 50%;
            position: absolute;
            transform: rotate(-90deg);
            background-color: var(--btn-mask-color);
        }
        .left-top-mask{
            --delay: 5;
            visibility: hidden;
            top: 0;
            border-top: .2rem;
            border-left: .2rem;
            border-top-left-radius: var(--btn-radius);
            transform-origin: 100% 100%;
            transform: rotate(-90deg);
        }
        .left-bottom-mask{
            --delay: 4;
            visibility: hidden;
            bottom: 0;
            border-bottom: .2rem;
            border-left: .2rem;
            border-bottom-left-radius: var(--btn-radius);
            transform-origin: 100% 0%;
            transform: rotate(-90deg);
        }
    }

    .middle-mask-container{
        width: calc(100% - 2rem);
        height: var(--btn-height);

        div{
            width: 100%;
            height: 50%;
            position: absolute;
            visibility: hidden;
            background-color: var(--btn-mask-color);
        }
        .middle-top-mask{
            --delay: 0;
            top: 0;
        }
        .middle-bottom-mask{
            --delay: 3;
            right: 0;
            bottom: 0;
        }
    }

    .right-mask{
        right: 0;
        height: var(--btn-height);
        width: var(--btn-radius);
        div{
            width: 100%;
            height: 50%;
            position: absolute;
            background-color: var(--btn-mask-color);

        }
        .right-top-mask{
            --delay: 1;
            visibility: hidden;
            top: 0;
            border-top: .2rem;
            border-right: .2rem;
            border-top-right-radius: var(--btn-radius);
            transform-origin: 0% 100%;
            transform: rotate(-90deg);
        }
        .right-bottom-mask{
            --delay: 2;
            visibility: hidden;
            bottom: 0;
            border-bottom: .2rem;
            border-right: .2rem;
            border-bottom-right-radius: var(--btn-radius);
            transform-origin: 0% 0%;
            transform: rotate(-90deg);
        }

    }

    // mobile适配
    @media screen and (max-width: 680px){
        --btn-height: 2rem;
        --btn-font-size: .8rem;
        --btn-radius: 1rem;
    }

    @media screen and (min-width: 680px){
        --btn-height: 2.5rem;
        --btn-font-size: .8rem;
        --btn-radius: 1.25rem;
    }

}

.btn-friend{
    box-shadow: 0px 1px 1px 1px var(--shadow-color);
    &:hover{
        transition: box-shadow .13s linear;
        box-shadow: 0px 2px 2px 1px var(--shadow-color);
    }
    a.friend-name{
        text-decoration: none;
        color: var(--btn-bg);
    }
    div.friend-profile{
        position: absolute;
        left: -.2rem;
        width: 2.7rem;
        height: 2.7rem;
        background-position: center;
        background-size: cover;
    }
    @media screen and (min-width: 680px){
        --btn-height: 2.2rem;
    }
}

.btn-support{
    box-shadow: 0px 1px 1px 1px var(--shadow-color);
    &:hover{
        transition: box-shadow .13s linear;
        box-shadow: 0px 2px 2px 1px var(--shadow-color);
    }
    a.support-name{
        text-decoration: none;
        color: var(--btn-bg);
    }
    div.support-profile{
        position: absolute;
        left: -.2rem;
        width: 3.2rem;
        height: 2.7rem;
        background-size: contain;
    }
    @media screen and (min-width: 680px){
        --btn-height: 2.2rem;
    }
}

.btn-permutation{
    background: linear-gradient(120deg, var(--xiaoxi-color), var(--ailurus-color));
}

.panel-pin-btn{
    position: absolute;
    right: .7rem;
    top: .7rem;
    padding: .5rem;
    cursor: pointer;
    i{
        color: var(--audio-font-color);
    }
    &:hover{
        background-color: var(--audio-hover-color);
        border-radius: 2rem;
    }
}

// 按钮 & 进度条动画
.wrapper-click{
    animation: oneBeat 0.45s ease-out;
    .left-top-mask{
        animation: left-top-route var(--piece) linear forwards calc(var(--piece) * var(--delay));
    }

    .left-bottom-mask{
        animation: left-bottom-route var(--piece) linear forwards calc(var(--piece) * var(--delay));
    }

    .middle-top-mask{
        animation: middle-top-line var(--piece) linear forwards calc(var(--piece) * var(--delay));
    }

    .middle-bottom-mask{
        animation: middle-bottom-line var(--piece) linear forwards calc(var(--piece) * var(--delay));
    }

    .right-bottom-mask{
        animation: right-bottom-route var(--piece) linear forwards calc(var(--piece) * var(--delay));
    }

    .right-top-mask{
        animation: right-top-route var(--piece) linear forwards calc(var(--piece) * var(--delay));
    }
}


@keyframes left-top-route {
    0% {
        transform: rotate(-90deg);
        transform-origin: right bottom;
        visibility: visible;
    }
    100% {
        visibility: visible;
        transform: rotate(0deg);
        transform-origin: right bottom;
    }
}

@keyframes left-bottom-route {
    0% {
        transform: rotate(-90deg);
        transform-origin: right top;
        visibility: visible;
    }
    100% {
        visibility: visible;
        transform-origin: right top;
        transform: rotate(0deg);
    }
}

@keyframes middle-top-line {
    0%{
        width: 0;
    }
    100%{
        width: 100%;
        visibility: visible;
    }
}

@keyframes middle-bottom-line {
    0%{
        width: 0;
        visibility: visible;
    }
    100%{
        width: 100%;
        visibility: visible;
    }
}

@keyframes right-top-route {
    0% {
        transform-origin: left bottom;
        transform: rotate(-90deg);
    }
    100% {
        visibility: visible;
        transform-origin: left bottom;
        transform: rotate(0deg);
    }
}

@keyframes right-bottom-route {
    0% {
        transform-origin: left top;
        transform: rotate(-90deg);
    }
    100% {
        visibility: visible;
        transform-origin: left top;
        transform: rotate(0deg);
    }
}

// 每一个人的主题色
.xiaoxi{
    .btn-wrapper{
        div.mask-gray{
            background-color: var(--xiaoxi-color);
        }
    }
    .btn-per{
        background-color: var(--xiaoxi-color);
    }
}

.xiaotao{
    .btn-wrapper{
        div.mask-gray{
            background-color: var(--xiaotao-color);
        }
    }
    .btn-per{
        background-color: var(--xiaotao-color);
    }
}

.xiaorou{
    .btn-wrapper{
        div.mask-gray{
            background-color: var(--xiaorou-color);
        }
    }
    .btn-per{
        background-color: var(--xiaorou-color);
    }
}

.reine{
    .btn-wrapper{
        div.mask-gray{
            background-color: var(--reine-color);
        }
    }
    .btn-per{
        background-color: var(--reine-color);
    }
}

.ailurus{
    .btn-wrapper{
        div.mask-gray{
            background-color: var(--ailurus-color);
        }
    }
    .btn-per{
        background-color: var(--ailurus-color);
    }
}

.yangbao{
    .btn-wrapper{
        div.mask-gray{
            background-color: var(--yangbao-color);
        }
    }
    .btn-per{
        background-color: var(--yangbao-color);
    }
}
