.nav-container{
    position: sticky;
    top: 0;
    z-index: 10;
    // mobile适配
    @media screen and (max-width: 680px){
        display: none;
    }
}

.navbar{
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom:.1rem solid var(--global-font-color);
    background-color: var(--nav-bg-color);
    
    .nav-item{
        margin: 0 1rem;
        padding: .3rem 0;
        font-weight: 600;
        font-size: 1.1rem;
        text-decoration: none;
        border-bottom: .15rem solid transparent;

        .nav-small-item{
            width: 100%;
            height: 100%;
        }
        .xx{
            color: var(--xiaoxi-color);
        }
        .xt{
            color: var(--xiaotao-color);
        }
        .xr{
            color: var(--xiaorou-color);
        }
        .reine{
            color: var(--reine-color);
        }
        .ailurus{
            color: var(--ailurus-color);
        }  
        .yangbao{
            color: var(--yangbao-color);
        }  
    }

    .top-nav-active{
        border-bottom: .15rem solid var(--link-blue);
    }
    
}

.float-navbar{
    position: fixed;
    bottom: 7.5rem;
    right: 1.5rem;
    width: 2.6rem;
    height: 2.6rem;
    background-color: #bdbebd;
    border-radius: 1.4rem;
    cursor: pointer;
    border: .1rem solid #bdbebd;
    box-shadow: 0 1px 2px 2px var(--shadow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;

    i{
        position: absolute;
        font-size: 2.6rem;
    }

    .float-nav-container{
        position: relative;
        width: 100%;
        height: 100%;
        
        .float-nav-item{
            visibility: hidden;
            position: absolute;
            width: inherit;
            height: inherit;
            border-radius: 1.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        a{
            display: block;
            box-shadow: 0 .1rem .2rem .1rem #6a6a6a;
        }

        .toTop{
            background-color: var(--float-nav-toTopbtn-bg);
            i{
                font-size: 1.3rem;
                color: var(--panel-bg-color);
            }

        }

        .showSidebar{
            background-color: var(--float-nav-side-btn-bg);
            i{
                font-size: 1.3rem;
                color: var(--panel-bg-color);
            }
            @media screen and (min-width: 680px){
                display: none;
            }
        }

        .float-nav-item-active{
            background-color: var(--link-blue);
        }
        
    }
}

.active-e{
    i.i-sandian{
        animation: eli-route .7s ease forwards;
    }
    .toTop{
        animation: fly-top 1s ease forwards;
    }
    .showSidebar{
        animation: fly-left 1s ease forwards;
    }
}

.not-active-e{
    i{
        animation: eli-disroute .7s ease forwards;
    }
    .toTop{
        bottom: 0;
        visibility: hidden;
    }
    .showSidebar{
        bottom: 0;
        visibility: hidden;
    }
}

// sidebar
.sidebar-mask{
    &::before{
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--sidebar-before);
        opacity: 0.6;
        z-index: 11;
    }
}

.sidebar-container{
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    background-color: var(--panel-bg-color);
    overflow: auto;
    z-index: 11;

    .sidebar-item{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .sidebar-info{
            color: var(--info-font-color);
            font-weight: 600;
            margin: 1rem 1rem;
            word-wrap: break-word;
            text-align: center;
        }

        div.i18n-container{
            left: 20%;
        }

    }
}

.sidebar-noshow{
    display: none;
    
}

.sidebar-back{
    animation: cancel .8s forwards;
    .sidebar-container{
        animation: slideBack .8s ease forwards;
    }
    .sidebar-mask{
        animation: fadeblack .8s linear forwards;
    }
}

.sidebar-active{
    display: block;
    touch-action: none;
    .sidebar-container{
        animation: slideLeft .5s ease;
    }
}

.sidebar-vup-icon{
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: .5rem;
    border-radius: 1.5rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.sidebar-item-active{
    background-color: var(--link-blue);
}

// float-nav 动画
@keyframes eli-route {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(90deg);
    }
}

@keyframes eli-disroute {
    0%{
        transform: rotate(90deg);
    }
    100%{
        transform: rotate(0deg);
    }
}

@keyframes fly-top {
    0%{
        transform: translateY(0);
        opacity: 0;
    }
    100%{
        visibility: visible;
        opacity: 1;
        transform: translateY(-130%);
    }
}

@keyframes fly-left {
    0%{
        opacity: 0;
        transform: translateX(0);
    }
    100%{
        visibility: visible;
        opacity: 1;
        transform: translateX(-130%);
    }
}

@keyframes slideLeft{
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0%);
    }
}

@keyframes slideBack{
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-100%);
        display: none;
    }
}

@keyframes fadeblack{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        display: none;
    }
}

@keyframes cancel{
    from{
        display: block;
    }
    to{
        display: none;
    }
}
