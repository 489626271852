// panel滚动条样式
.panel-root{
    overflow: auto;
    &::-webkit-scrollbar{
        width: .5rem;
        height: .1rem;
    }

    &::-webkit-scrollbar-thumb{
        border-radius: .1rem;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    }

    &::-webkit-scrollbar-track{
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: var(--panel-bg-color);
    }

    // 每个人的颜色
    &.xiaoxi::-webkit-scrollbar-thumb{
        background-color: var(--xiaoxi-color);
    }

    &.xiaotao::-webkit-scrollbar-thumb{
        background-color: var(--xiaotao-color);
    }

    &.xiaorou::-webkit-scrollbar-thumb{
        background-color: var(--xiaorou-color);
    }

    &.reine::-webkit-scrollbar-thumb{
        background-color: var(--reine-color);
    }

    &.ailurus::-webkit-scrollbar-thumb{
        background-color: var(--ailurus-color);
    }
}
// 音声卡片默认样式
.panel-container{
    border-radius: .2rem;
    margin: 1rem;
    box-shadow: 0 .1rem .2rem var(--shadow-color);
    background-color: var(--panel-bg-color);
    position: relative;

    .panel-info-container{
        display: flex;
        align-items: center;
        position: relative;

        div{
            display: inline-block;
        }

        .panel-tags{
            font-weight: 600;
            margin: 1rem 1rem 0 1rem;
            color: var(--info-font-color);
        }

        .panel-alias{
            margin: 1rem 0 0 0;
            font-weight: 500;
            color: var(--global-font-color);
        }

    }

    .panel-btn-container{
        padding: .5rem 0;
        display: flex;
        flex-wrap: wrap
    }

    .heimu{
        width: auto;
        background-color: #252525;
        color: #252525;
        text-shadow: none;

        &:hover{ transition: color .13s linear; color: whitesmoke;}
    }

    // 显示最新的音声panel右上角的标致
    .panel-new{
        display: none;
        position: absolute;
        right: 0;

        i.i-new{
            color: #d81e06;
            font-size: 4rem;
        }

    }

    .isNew{
        display: block;
    }

    // mobile适配
    @media screen and (max-width: 680px){
        .panel-tags{
            font-size: 1.1rem;
        }
        .panel-alias{
            font-size: 1.1rem;
        }
    }

    @media screen and (min-width: 680px){
        .panel-tags{
            font-size: 1.4rem;
        }
        .panel-alias{
            font-size: 1.4rem;
        }
    }

}
// 音声控制卡片默认样式
.audioPanel-container{
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 11;
    background-color: var(--audio-panel);
    color: var(--audio-font-color);
    border-top: 1px solid rgba(255, 255, 255, 0.5);

    .audioinfo{
        text-align: center;
        padding: 1rem 0 .5rem 0;
    }

    .audio-info-show{
        animation: zoomIn 1s;
    }

    .audioControl-container{
        display: flex;
        flex-direction: row;
        justify-content: center;

        div{
            i{
                font-size: 1.7rem;
            }
            margin: 0 1.2rem .5rem 1.2rem;
            padding: .5rem;
            border-radius: 2rem;
            cursor: pointer;
        }

        div:hover{
            background-color: var(--audio-hover-color);
        }

        .control-btn-click{
            animation: oneBeat 1s;
        }

        .cbtn-bg-click{
            animation: pulse 1s;
        }

    }

    .loopInfo{
        font-size: .7rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-bottom: .7rem;
        span{
            display: block;
        }
    }

}
// 网站公告默认样式 & danmaku组件默认样式
.annoPanel-container{
    display: flex;
    justify-content: center;
    padding: 2rem 1rem;
    align-items: center;
    position: relative;
    color: var(--info-font-color);

    .profile-container{
        position: relative;
        margin: 0 1rem;

        .profile{
            width: 6rem;
            height: 6rem;
            border-radius: 3rem;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .profile-border{
            position: absolute;
            top: 0;
            width: 6rem;
            height: 6rem;
            background-color: transparent;
            border-radius: 3rem;
        }

        .profile-info{
            display: none;
            font-weight: 600;
            padding: .3rem .2rem;
            border-radius: .6rem;
            background-color: red;
            border: 1px solid white;
            text-align: center;
            cursor: pointer;
            a{
                text-decoration: none;
                color: white;
            }
        }

    }



    #danmu-collect{
        width: 20%;
    }

    .site-info{
        font-size: 1.3rem;
        line-height: 1.7rem;
        text-align: center;

        .site-inner{
            padding: .2rem 0;
            letter-spacing: .1rem;
        }

        .site-title{
            font-weight: 700;
        }

        .site-gray{
            font-size: .7rem;
            color: #d0e6ff;
        }

        .site-important{
            font-size: .9rem;

            i.i-gitee{
                font-size: 1.1rem;
                color: red;
                margin: 0 0 0 .5rem;
            }

            a{
                display: inline-block;
                margin: 0 0 0 .5rem;
            }

            i.i-heart{
                font-size: 1rem;
                color: red;
                animation: oneBeat .6s linear infinite;
            }

        }

        .shields-container{
            display: flex;
            justify-content: center;

            img{
                padding: 0 .5rem;
            }
        }

        .heimu{
            width: auto;
            background-color: #252525;
            color: #252525;
            text-shadow: none;

            &:hover{ transition: color .13s linear ; color: #d0e6ff; }
        }

    }

    // mobile适配
    @media screen and (max-width: 680px){
        --head-img-padding: 1.5rem;
        --head-img-wh: 3rem;
    }

    @media screen and (min-width: 680px){
        --head-img-padding: 5rem;
        --head-img-wh: 10rem;

    }

}
// 弹幕组件默认样式
.danmaku-container-panel{
    width: 100%;
    height: 100%;
    position: absolute;

    .danmaku-container{
        box-sizing: border-box;
        padding: 1rem;
        width: 100%;
        height: 100%;
        div{
            width: auto !important;
        }
    }

}
// 网站Footer组件默认样式
.footer-container-panel{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 1rem 1rem;
    padding-top: 0.5rem;
    border-top: 0.1rem solid var(--global-shadow);
    color: var(--info-font-color);

    a{
        color: var(--link-blue);
    }

    div{
        margin-top: 0.5rem;
    }

    #footer-copyright{
        display: flex;
        justify-content: center;
        align-items: center;
        .footer-profile{
            margin-top: 0;
            margin-left: .2rem;
            margin-right: .2rem;
            width: 1.2rem;
            height: 1.2rem;
            display: inline-block;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: .8rem;
        }
    }

    #footer-powerby{

        .i-playReact{
            animation: route-react 10s linear infinite;
        }

        a{
            color: #61dafb;
            font-weight: bold;
            text-decoration: none;
        }

        #footer-beian{
            margin-bottom: 1rem;

            #gongan{
                margin-left: 1rem;
            }
        }
    }

    @keyframes route-react {
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }

}

// index mobile 导航
.indexNavPanel{
    .indexNav-Container{
        display: flex;
        justify-content: center;
    }

    .indexNav-info{
        padding: .2rem 0 .5rem 0;
        font-size: .9rem;
        color: skyblue;
        font-weight: bolder;
        text-align: center;
    }
}

@media screen and (max-width: 680px){
    .indexNavPanel{
        display: block;
    }
    .feature-panel-container{
        top: 1rem;
    }
}

@media screen and (min-width: 680px){
    .indexNavPanel{
        display: none;
    }
    .feature-panel-container{
        top: 4rem;
    }
}

// 功能面版
.feature-panel-container{
    z-index: 9;
    border-top: 2px solid rgba(255, 255, 255, 0.5);
    opacity: .8;
    backdrop-filter: blur(1px);
    margin-bottom: 0;
}

// 排列组合面板
.per-panel-container{
    display: flex;
    align-items: center;
    position: relative;

    i{
        font-size: 1.7rem;
        color: var(--audio-font-color);
    }

    .per-btnlist-container{
        overflow-x: auto;
        overflow-y: hidden;

        .per-btnlist{
            display: flex;
            flex-shrink: 0;
            text-shadow: none;
            opacity: 1;

            .btn-per-mask{
                word-break: keep-all;
                .xiaoxi{
                    background-color: var(--xiaoxi-color);
                }

                .xiaotao{
                    background-color: var(--xiaotao-color);
                }

                .xiaorou{
                    background-color: var(--xiaorou-color);
                }

                .reine{
                    background-color: var(--reine-color);
                }

                .ailurus{
                    background-color: var(--ailurus-color);
                }
            }
        }

        &::-webkit-scrollbar{
            width: .1rem;
            height: .5rem;
        }

        &::-webkit-scrollbar-thumb{
            border-radius: .25rem;
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        }

        &::-webkit-scrollbar-track{
            border-radius: .25rem;
            box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: #EDEDED;
        }

        // 每个人的颜色
        &.xiaoxi::-webkit-scrollbar-thumb{
            background-color: var(--xiaoxi-color);
        }

        &.xiaotao::-webkit-scrollbar-thumb{
            background-color: var(--xiaotao-color);
        }

        &.xiaorou::-webkit-scrollbar-thumb{
            background-color: var(--xiaorou-color);
        }

        &.reine::-webkit-scrollbar-thumb{
            background-color: var(--reine-color);
        }

        &.ailurus::-webkit-scrollbar-thumb{
            background-color: var(--ailurus-color);
        }
    }

    .per-control{
        display: flex;
        align-items: center;


        div{
            margin: 0 .1rem;
            padding: .5rem;
            border-radius: 2rem;
            cursor: pointer;
        }

        div:hover{
            background-color: var(--audio-hover-color);
        }
    }

}

// 面版pin到页面上
.panel-pinup{
    position: sticky;
}

// 面板不pin
.panel-not-pinup{
    position: relative;
    top: 0rem;
}


// 动画
// audio panel 按钮动画
@keyframes oneBeat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.85);
        transform: scale(0.85);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

// 入场动画
.siteinfoZoomIn{
    --zoomInDelay: 0;
    animation: zoomIn 1s;
    animation-delay: calc(1s * var(--zoomInDelay));
}
.drawPanelZoomIn{
    --zoomInDelay: 0;
    animation: zoomIn 1s;
    animation-delay: calc(1s * var(--zoomInDelay));
}
.dynamicZoomIn{
    --zoomInDelay: 0;
    animation: zoomIn 1s;
    animation-delay: calc(1s * var(--zoomInDelay));
}
.voicePanelZoomIn{
    --zoomInDelay: 0;
    animation: zoomIn 1s;
    animation-delay: calc(1s * var(--zoomInDelay));
}
