@font-face {
  font-family: "iconfont"; /* Project id 2926838 */
  src: url('//at.alicdn.com/t/font_2926838_mmlmmwk3jgi.woff2?t=1642409212945') format('woff2'),
       url('//at.alicdn.com/t/font_2926838_mmlmmwk3jgi.woff?t=1642409212945') format('woff'),
       url('//at.alicdn.com/t/font_2926838_mmlmmwk3jgi.ttf?t=1642409212945') format('truetype'),
       url('//at.alicdn.com/t/font_2926838_mmlmmwk3jgi.svg?t=1642409212945#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.icon-bofang:before {
  content: "\e60b";
}

.icon-a-shanchu:before {
  content: "\e607";
}

.icon-quxiaodatouzhen:before {
  content: "\e6ef";
}

.icon-datouzhen:before {
  content: "\e62b";
}

.icon-xiangxiajiantouxiao:before {
  content: "\e64e";
}

.icon-ictotop:before {
  content: "\e652";
}

.icon-sidebar:before {
  content: "\e615";
}

.icon-yemiandingbulangongnenganniu-gengduo-hei:before {
  content: "\e61f";
}

.icon-heart-fill:before {
  content: "\e849";
}

.icon-xunhuanbofang:before {
  content: "\e6cc";
}

.icon-moon:before {
  content: "\e981";
}

.icon-sun:before {
  content: "\e789";
}

.icon-suijibofang:before {
  content: "\e601";
}

.icon-react:before {
  content: "\e712";
}

.icon-jingledan-tingzhibofang:before {
  content: "\e737";
}

.icon-in-en:before {
  content: "\e600";
}

.icon-lamp:before {
  content: "\e66b";
}

.icon-intel:before {
  content: "\e6a2";
}

.icon-Amd:before {
  content: "\edd7";
}

.icon-huanxiong:before {
  content: "\e693";
}

.icon-sharpicons_Rabbit:before {
  content: "\e78a";
}

.icon-github:before {
  content: "\e620";
}

.icon-gitee:before {
  content: "\e619";
}

.icon-New-Tga:before {
  content: "\ea8a";
}